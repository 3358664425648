.hero-wrapper {
  color: var(--white);
}
.hero-wrapper-responsive {
  height: 100vh;
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .hero-wrapper-responsive {
    height: auto;
    padding-top: 3rem;
  }
  .sub-text {
    margin-bottom: 40px;
  }
}
@media (max-width: 540px) {
  .hero-wrapper-responsive {
    height: auto;
    padding-top: 6rem;
  }
  .sub-text {
    margin-bottom: 62px;
  }
}

.hero-text {
  font-weight: 900;
  font-size: var(--fs-xl);
  // line-height: 15.625rem;
  line-height: var(--fs-xl);
  letter-spacing: 2px;
}

.sub-text {
  // opacity: 0.6;
  color: var(--white);
  font-weight: 500;
  font-size: var(--fs-40);
  max-width: 1100px;
}

.content-wrapper {
  padding-top: 90px;
}

.sub-content-text {
  color: var(--white);
  opacity: 0.9;
  font-size: var(--fs-40);
  margin-bottom: 40px;
  // text-align: justify;
  text-align: start;
  max-width: 1060px;
}
.sub2-content-text {
  color: var(--white);
  opacity: 0.9;
  font-size: var(--fs-40);
  padding-top: 20px;
  // text-align: justify;
  text-align: start;
  max-width: 1060px;
  margin: 0px;
}

@media (min-width: 1181px) {
  .sub-text {
    line-height: 47px;
  }
  .sub-content-text {
    line-height: 47px;
  }
}
@media (max-width: 768px) {
  .sub-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sub-content-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sub2-content-text {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.padding-top20 {
  padding-top: 20px;
}

.square-position-1 {
  top: 0px;
  // left: -198px;
  left: 0px;
}

.button-section {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  border-radius: 5px;
  height: 63px;
  width: fit-content;
  color: var(--primary);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.button-divider {
  height: 100%;
  width: 1px;
  background-color: var(--primary);
}
.button-border {
  border-left: 1px solid var(--primary);
  // border-right: 1px solid var(--primary);
}
.button-text {
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.1px;
  font-weight: 500;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .button-section {
    height: 47px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .button-text {
    font-size: 0.9rem;
  }
}
