.grid-layout {
  background-color: var(--primary);
  min-height: 100vh;
  width: 100vw;
  // overflow: hidden;
}
.grid-child-zindex {
  z-index: 1;
  padding-bottom: 80px;
}

.bg-square {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.1) 0.11em,
      transparent 0.11em
    ),
    linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0.11em, transparent 0.11em);
  background-size: 3em 3em;
  background-repeat: repeat;
}

// position: absolute;
// top: 0px;
// left: -198px;
// width: 220%;
// height: 239%;
// width: 100%;
// height: 100%;
// background-image: linear-gradient(#ffffff1c 0.11em, transparent 0.11em),
//   linear-gradient(90deg, #ffffff1c 0.11em, transparent 0.11em);
// background-size: 3em 3em;
// background-repeat: repeat;
// transform: rotate(12deg);
